.tran-map-container {
  min-height: calc(100vh - 439px);

  @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
    min-height: calc(100vh - 387px);
  }
}


.main-with-right-sidebar {
  .tran-map-container {
    min-height: calc(100vh - 439px);

    @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
      min-height: calc(100vh - 556px);
    }
    @media screen and (min-width: 1368px) {
      // the Years bar pops down 100px
      min-height: calc(100vh - 556px - 100px);
    }
  }

}


.clickable-popups {
  .mapboxgl-popup-content {
    cursor:pointer;
    span {
      font-size:14px;
    }
  }
}
