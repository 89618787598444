.card {
  .card-body {
    .card-title {
      font-family: $font-family-title;
      font-weight: $font-weight-title;
      font-size: 1.5rem;
      color: $black-200;
      padding: 0 0 18px;
      small {
        line-height: 2rem;
      }
    }
  }
}