.info-box {
    font-size: 0.7500rem;
    color: $gray-900;
    position: relative;
    padding-left: 14px;

    &-no-alert {
        padding-left: 0;
    }
    
    &-divider {
        margin: 0 15px;
    }
}
