.rbt-input-wrapper > .rbt-token  {
  background: $primary;
  border: none;
  color: #fff;
  font-size: 0.9rem;
  padding: 0 30px 0 10px;
  vertical-align: middle;
  border-radius: 4px;
  box-sizing: border-box;
  line-height: 24px;

  .rbt-token-remove-button {
    background: hsla(0,0%,100%,.2) no-repeat 50%/8px;
    background-image: none;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8.614' height='8.64'%3E%3Cg data-name='Group 1' fill='none' stroke='%23fff' stroke-linecap='square' stroke-miterlimit='10' stroke-width='2'%3E%3Cpath d='m1.735 6.931 5.464-5.517'/%3E%3Cpath data-name='Line' d='m1.414 1.414 5.753 5.811'/%3E%3C/g%3E%3C/svg%3E");
    border: none;
    border-radius: 100%;
    color: #fff;
    font-size: 0;
    height: 18px;
    left: auto;
    line-height: 18px;
    padding: 0 0 0 10px;
    right: 4px;
    text-align: center;
    top: 3px;
    transition: all .2s ease-in-out;
    width: 18px;
  }
}

.rbt-input .rbt-input-main {
  &::placeholder {
    color: $input-placeholder-color;
    // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
    opacity: 1;
  }
}
