.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    padding: 0 $grid-gutter-width / 2 80px;
    z-index: 1002;
    display: none;
    flex-direction: column;
    justify-content: space-between;


    @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
        & {
            display: flex;
        }
    }

    &-no-footer {
        padding-bottom: 0;
    }

    .content-title {
        display: block;
    }

    &-scroll {
        height: 100vh;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
    }

    &-left {
        left: 0;
        width: $sidebar-left-width; 
        border-right: 1px solid $gray-400;
    }

    &-right {
        right: 0;
        width: $sidebar-right-width;
        border-left: 1px solid $gray-400;
        max-width: calc(100% - #{$sidebar-mobile-left-width});

        @media screen and (min-width: map-get($grid-breakpoints, 'md')) and (max-width: map-get($grid-breakpoints, 'xl') - 1) {
            width: 350px; 
        }
    }

    &-close {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 18px;
    }

    &-toggle {
        display: none;

        @media screen and (max-width: map-get($grid-breakpoints, 'lg') - 1) {
            display: block;
        }
    }

    &-header {
        font-size: 1.3750rem;
        line-height: 1.2;
        color: $black-100;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: $navbar-height;
        border-bottom: 1px solid $gray-400;
        position: relative;
    }

    &-logo {
        padding-left: 12px;
        height: auto;
        max-width: 100%;
        width: 11rem;
        display: block;
        img {
            display: block;
            height: auto;
            max-height: 70px;
            width: auto;
            max-width: 100%;
        }
    }

    &-section {
        padding: $grid-gutter-width / 2 0;
        
        &:not(:last-child) {
            border-bottom: 1px solid $gray-400;
        }

        &-title {
            font-size: 0.7500rem;
            color: $black-100;
            text-transform: uppercase;

            &-icon {
                margin: -2px 5px 0 0; 
            }
        }

        &-content {
            p {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &-footer {
        font-size: 0.8750rem;
        color: $gray-800;
        padding: 20px;
        position: absolute;
        left: $grid-gutter-width / 2;
        right: $grid-gutter-width / 2;
        bottom: 0;
        border-top: 1px solid $gray-400;
    }

    .nav-link {
        color: $gray-900;
        padding: 12px 0 12px 50px;
        min-height: 48px;
        position: relative;
        border-radius: $border-radius;

        &:hover,
        &.active {
            color: $primary;
            background: rgba($orange-100, 0.45);
            
            .icon-stroke {
                stroke: $primary;
            }

            .icon-fill {
                fill: $primary;
            }
        }

        svg {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 16px;
            margin: -2px 0 0 0;

            .icon-fill,
            .icon-stroke {
                transition: $transition-base;
            }
        }
    }

    .sidebar-footer-logo {
        max-width: 8rem;
    }

    @media screen and (max-width: map-get($grid-breakpoints, 'md') - 1) {
        display: flex;

        &:not(.sidebar-show) {
            &.sidebar-left {
                display: none;
            }
        }
    }

    @media screen and (max-width: map-get($grid-breakpoints, 'lg') - 1) {
        &:not(.sidebar-show) {
            &.sidebar-left {
                width: $sidebar-mobile-left-width;

                .sidebar-toggle {
                    margin-left: -12px;
                }

                .sidebar-header {
                    justify-content: center;
                }

                .sidebar-footer-logo,
                .sidebar-logo {
                    width: 0;
                }

                .nav-item {
                    font-size: 0;

                    svg {
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }

                .sidebar-footer {
                    font-size: 0;
                }
            }
        }
    }
}
