.badge {
    width: 26px;
    height: 26px;
    line-height: 26px;
    padding: 0;
    margin: -5px 0;
    
    &-icon {
        &-check {
            margin: 0 0 3px 1px !important;
        }
    }
}

.btn .badge {
    top: 0;
}