form {
    label {
        font-size: 0.8750rem;
        color: $black-200;
    }
}

.form {
    &-upload {
        display: flex;
        height: calc(100vh - 151px);
        justify-content: center;
        align-items: center;
        padding: 10px 0;

        @media screen and (max-width: map-get($grid-breakpoints, 'md') - 1) {
            height: auto;
        }

        &-inner {
            max-width: 570px;
            max-height: 100%;
            width: 100%;
        }

        &-title {
            font-family: $font-family-title;
            font-size: 3rem;
            line-height: 1.1;
            color: $primary;
            padding: 8px 0;

            @media screen and (max-width: map-get($grid-breakpoints, 'md') - 1) {
                font-size: 2rem;
            }
        }

        &-description {
            font-size: 1.1250rem;
            color: $black-200;
            padding: 8px 0;

            @media screen and (max-width: map-get($grid-breakpoints, 'md') - 1) {
                font-size: 1rem;
            }
        }

        &-container {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-top: 1px solid $border-color;
            border-bottom: 1px solid $border-color;
            position: relative;
            padding: 20px 0;
            margin: 20px 0;

            @media screen and (max-width: map-get($grid-breakpoints, 'md') - 1) {
                margin: 10px 0;
            }
        }

        &-file {
            &-info {
                flex-grow: 1;
                display: flex;
                align-items: center;
                min-height: 40px;
            }

            &-icon {
                padding: 0 16px 0 0;
            }

            &-name {
                font-size: 1.1250rem;
                color: $gray-900;

                @media screen and (max-width: map-get($grid-breakpoints, 'md') - 1) {
                    font-size: 1rem;
                }
            }

            &-progress {
                &-value {
                    font-size: 0.75rem;
                    color: $primary;
                    margin-top: 2px;
                }

                &-line {
                    position: absolute;
                    left: 0;
                    bottom: -1px;
                    height: 4px;
                    background: $primary;
                    transition: width 0.2s ease;
                }
            }
        }

        &-controls {
            margin: 0 -5px;
            padding: 20px 0;

            .btn {
                margin: 5px;
                min-width: 180px;

                @media screen and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
                    width: 100%;
                }
            }

            @media screen and (max-width: map-get($grid-breakpoints, 'md') - 1) {
                margin: 0;
                padding: 10px 0;

                .btn {
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }

        &-btn {
            position: relative;

            input {
                position: absolute;
                top: 0;
                left: 0;
                width: 0;
                height: 0;
                opacity: 0;
            }
        }
    }

    &-check {
        position: relative;
        padding: 0;
        margin-top: 5px;
        margin-bottom: 5px;

        .form-check-input {
            width: 0;
            height: 0;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;

            &:checked + label {
                &::before {
                    background: $primary;
                }

                &::after {
                    opacity: 1;
                }
            }
        }

        .form-check-label {
            color: $gray-900;
            display: inline-block;
            vertical-align: middle;
            padding-left: 38px;
            min-height: 24px;
            width: 100%;

            &::before,
            &::after {
                content: '';
                position: absolute;
                height: 24px;
                width: 24px;
                border-radius: $border-radius;
                left: 0;
                top: 0;
            }

            &::before {
                background: $gray-700;
            }

            &::after {
                background: $primary no-repeat center / 15px auto;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.572' height='12.296' viewBox='0 0 14.572 12.296'%3E%3Cg transform='translate(2.121 2.118)'%3E%3Cpath d='M0,4.881,3.119,8l7.213-8' transform='translate(0)' fill='none' stroke='%23fff' stroke-linecap='square' stroke-miterlimit='10' stroke-width='3'/%3E%3C/g%3E%3C/svg%3E");
                opacity: 0;
            }
        }
    }
}

.custom-select-wrap {
    .custom-select {
        padding: 5px 190px 4px 44px;

        &-text {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            margin: 0;
            width: 190px;
            margin: 0;
            padding: 0 0 0 18px;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 5px;
                bottom: 5px;
                width: 1px;
                background: $gray-400;
            }
        }
    }
}

.custom-select {
    padding-right: 5px;
    font-size: 0.8rem;

    option {
        background: $white;
        padding: 10px 20px;
        border: none;
    }
}

.input-group {
    &-search {
        width: auto;
        flex: 1;
        position: relative;

        @media screen and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
            .input-group-append {
                .btn {
                    font-size: 12px;
                }
            }
        }

        .form-control {
            padding-left: 44px;

            @media screen and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
                font-size: 12px;
            }
        }

        &::before {
            content: '';
            position: absolute;
            left: 16px;
            top: 9px;
            background: no-repeat center / contain;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23747576' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-search'%3E%3Ccircle cx='11' cy='11' r='8'%3E%3C/circle%3E%3Cline x1='21' y1='21' x2='16.65' y2='16.65'%3E%3C/line%3E%3C/svg%3E");
            width: 20px;
            height: 20px;
            transform: rotate(90deg);
            pointer-events: none;
            z-index: 10;
        }
    }

    &-text {
        font-size: 0.7500rem;
        text-transform: uppercase;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            top: 5px;
            bottom: 5px;
            width: 1px;
            background: $gray-400;
        }
    }



    &-prepend {
        .input-group-text {
            &::after {
                right: 0;
            }
        }
    }

    &-append {
        .btn {
            position: relative;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 5px;
                bottom: 5px;
                width: 1px;
                background: $gray-400;
            }
        }
    }
}

.dropdown-toggle {
    &::after {
        border: none;
        background: $custom-select-indicator no-repeat center right / $custom-select-bg-size;
        width: 17px;
        height: 10px;
        vertical-align: middle;
        margin-left: 20px;
    }
}

.navbar .dropdown-toggle {
  text-align: right;
  //min-width: 200px;
  //@media screen and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
  //  min-width: 150px;
  //}
    padding-left: 15px;
}

.grey {
    color: $gray-900;
}

.btn-clear {
    font-size: 27px;
    padding: 0px 10px 0px 10px;
    color: $gray-900;
}
