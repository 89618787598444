.user-menu {
    flex: 0 0 220px;
    display: none;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: nowrap;


    @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
        display: flex;
    }


    &-img {
        flex: 0 0 34px;

        img {
            display: block;
            width: 34px;
            height: 34px;
            border-radius: 100%;
        }
    }

    &-info {
        position: relative;
        padding: 0 30px 0 14px;
        white-space: nowrap;
        cursor: pointer;

        @media screen and (max-width: map-get($grid-breakpoints, 'md') - 1) {
            display: none;
        }

        svg {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    &-menu {
        position: absolute;
        display: none;
        background-color: $white;
        min-width: 250px;
        top: 70px;
        right: 5px;
        border: 1px solid $gray-200;
        padding: 0;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.19);
        z-index: 100;
        &.show {
            display: block;
        }
        button, a {
            width: 100%;
            padding: 10px 20px;
            text-align: left;
            background: none;
            color: inherit;
            border: none;
            font: inherit;
            cursor: pointer;
            outline: inherit;
            &:hover {
                background-color: $gray-100;
            }
        }
    }
}
