.ui-datepicker {
    padding: 27px 30px 30px;
    background: $white;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.19);

    @media screen and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
        padding: 12px 15px 15px;
    }


    &-prev,
    &-next {
        display: none;
    }

    &-month,
    &-year {
        display: inline-block;
        width: 130px;
        height: $custom-select-height;
        padding: $custom-select-padding-y ($custom-select-padding-x + $custom-select-indicator-padding) $custom-select-padding-y $custom-select-padding-x;
        font-family: $custom-select-font-family;
        font-size: $custom-select-font-size;
        font-weight: $custom-select-font-weight;
        line-height: $custom-select-line-height;
        color: $custom-select-color;
        vertical-align: middle;
        background: $custom-select-bg $custom-select-background;
        border: $custom-select-border-width solid $custom-select-border-color;
        border-radius: $custom-select-border-radius;
        appearance: none;
        margin: 3px;

        @media screen and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
            width: 99px;
        }

        &:focus {
            border-color: $custom-select-focus-border-color;
            outline: 0;
            box-shadow: $custom-select-focus-box-shadow;

            &::-ms-value {
                color: $input-color;
                background-color: $input-bg;
            }
        }

        &:disabled {
            color: $custom-select-disabled-color;
            background-color: $custom-select-disabled-bg;
        }

        &::-ms-expand {
            display: none;
        }

        &:-moz-focusring {
            color: transparent;
            text-shadow: 0 0 0 $custom-select-color;
        }
    }

    &-calendar {
        width: 100%;
        margin: 10px 0 0;

        td,
        th {
            height: 42px;

            a {
                &:hover {
                    color: $primary;
                }
            }

            span {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 28px;
                height: 28px;
            }
        }
    }


    .ui-state {
        &-default {
            font-size: 1rem;
            color: $gray-900;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 28px;
            height: 28px;
            border-radius: 4px;
            text-decoration: none;
        }

        &-highlight {
            color: $primary;
        }

        &-active {
            background: $primary;
            color: $white;
        }

        &-disabled:not(.ui-state-today) {
            opacity: 0.6;
        }
    }

}