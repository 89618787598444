.list-group-flush {
    > .list-group-item {
        .field {
            margin-bottom: 10px;
        }
        .field:last-child {
            margin-bottom: 0;
        }

        &:last-child {
            border-bottom-width: $list-group-border-width;
        }
    }
}

.list-group {
    &-item {
        &-title {
            font-size: 0.75rem;
            color: $black-200;
        }
        &-subtitle {
            font-size: 0.7rem;
            color: $black-200;
            font-style: italic;
        }

        &-text {
            &.large {
                font-family: $font-family-title;
                line-height: 1.1;
                font-size: 7.5rem;
                color: $primary;
            }
        }
    }
}

.list-numeric {
    padding: 0 20px;
    margin: 0;

    .list-numeric-item {
        list-style: none;
        position: relative;
        padding: 12px 0 12px 0px;
        
        a {
            color: $gray-900;
            display: block;
            margin: -12px 0 -12px -45px;
            padding: 12px 0 12px 30px;
            border-radius: $border-radius;
            text-decoration: none;
            border-left: 15px solid transparent;
            
            &:hover {
                color: $primary;
                background: rgba($orange-100, 0.15);  
            }
        }
    }
}

ol {
    &.list-numeric {
        counter-reset: numbers;

        .list-numeric-item {
            counter-increment: numbers;
            padding: 12px 0 12px 30px;
            &::before {
                content: counter(numbers, decimal-leading-zero);
                font-size: 0.7500rem;
                color: $primary;
                position: absolute;
                top: 15px;
                left: 0; 
                width: 30px;
            }
        }
    }
}
