.table {
    color: $gray-900;
    border: none;
    border-spacing: 0;
    border-collapse: separate;
    border-collapse: collapse;
    // min-width: 700px;

    &.table-alignment-top {
        th,
        td {
            vertical-align: top;
        }
    }

    .table-marker {
        display: inline-block;
        vertical-align: middle;
        font-family: "Roboto";
        font-size: 0.75rem;
        color: $gray-900;
        margin-left: 15px;
        padding-top: 1px;
        margin-bottom: 3px
    }

    .dropdown {
        display: inline-block;
        vertical-align: top;
    }

    &-icons-group {
        display: inline-block;
        vertical-align: middle;
        padding: 0 15px;

        &-item {
            text-decoration: none !important;
            padding: 0 10px;
        }
    }

    &.table-hover {
        tbody tr {

            cursor: pointer;

            &.active {
                td {
                    background-color: $table-hover-bg;
                }

                .form-check {
                    opacity: 1;
                }
            }

            &:hover {
                color: $gray-900;
                .form-check {
                    opacity: 1;
                }
            }
        }
    }

    .form-check {
        .form-check-input {
            &:checked + label {
                &::before {
                    background: $white;
                }
            }
        }

        .form-check-label {
            padding-left: 20px;
            min-height: 12px;

            &::before {
                height: 12px;
                width: 12px;
                background: transparent;
                border: 1px solid $gray-500;
                border-radius: 100%;
                left: 4px;
                top: 6px;

                @media screen and (max-width: map-get($grid-breakpoints, 'md') - 1) {
                    height: 16px;
                    width: 16px;
                    top: 5px;
                }
            }

            &::after {
                height: 8px;
                width: 8px;
                background: $primary;
                border-radius: 100%;
                top: 8px;
                left: 6px;
                opacity: 0;

                @media screen and (max-width: map-get($grid-breakpoints, 'md') - 1) {
                    height: 10px;
                    width: 10px;
                    top: 8px;
                    left: 7px;
                }
            }
        }
    }

    .col-title {
        font-family: $font-family-title;
        font-size: 1.3750rem;
        color: $white;
        text-transform: uppercase;
        background: $gray-900;
        padding: 0 50px;
        height: 38px;
        line-height: 1;
        border-top: 8px solid $white;

        @media screen and (max-width: map-get($grid-breakpoints, 'md') - 1) {
            font-size: 1.25rem;
        }
    }

    .col-checkbox {
        text-align: center;
    }

    .col-score {
        font-family: $font-family-title;
        font-size: 1.6250rem;
    }

    .col-name {
        font-family: $font-family-title;
        font-size: 1.12rem;
        color: $black-200;

        @media screen and (max-width: map-get($grid-breakpoints, 'md') - 1) {
            font-size: 1.25rem;
        }
        & .excerpt {
          font-size: smaller;
          display: block;
          .label {
            padding-right: 4px;
            font-style: italic;
          }
        }
    }

    .col-date {
        font-size: 0.9375rem;
    }

    .col-actions {
        text-align: right;
    }

    &.table-large {
        tbody {
            td {
                padding: 15px 10px;
            }
        }
    }

    tr {
        &:not(:last-child) {
            border-bottom: $table-border-width solid $table-border-color;
        }
    }

    th,
    td {
        vertical-align: middle;
        border: none;
    }

    thead {
        .dropdown-menu {
            margin-top: -16px;
        }

        .form-check {
            .form-check-label {
                &::before {
                    top: 1px;
                }

                &::after {
                    top: 3px;
                }
            }
        }

        th,
        td {
            font-size: 0.6875rem;
            text-transform: uppercase;
            border-bottom-width: 2 * $table-border-width;
            border-bottom-color: $black;
            padding: 5px 10px;

            .btn {
                line-height: 0.5;
            }
        }
        th.smaller,
        td.smaller {
            font-size: 0.5rem;
        }
    }

    tbody {
        .dropdown-menu {
            margin-top: -9px;
        }

        .form-check {
            opacity: 1;
        }

        td {
            padding: 10px;
        }

        .icon-mark {
            margin-top: 1px;
        }
    }

    .thead-light {
        th {
            border-bottom-color: $black;
        }
        th.no-border {
            border-bottom: 0;
        }
        th.active-border {
            border-bottom: 4px solid $primary;
        }
    }

    &-type-1 {

        @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
            table-layout: fixed;
        }

        thead {
            tr {
                &:first-child {
                    th:nth-child(2) {
                        border-bottom: 4px solid $primary;
                    }
                }
                th {
                    text-align: center;
                }
            }
        }

        tbody {
            tr {
                cursor: pointer;
                &.active {
                    td {
                        background: $orange-100;
                    }
                }

                &:first-child {
                    td {
                        vertical-align: top;
                    }
                }

                td {
                    font-size: 0.7500rem;
                    color: $black-200;
                    position: relative;

                    .table-line {
                        display: inline-block;
                        vertical-align: middle;

                        &:before {
                            content: "";
                            position: absolute;
                            left: 0;
                            right: 0;
                            margin: -2px 0 0;
                            height: 4px;
                            background: $primary;
                        }
                    }

                    &:last-child {
                        font-family: $font-family-title;
                        font-size: 1.6250rem;
                        text-align: center;
                    }

                    &:not(:first-child):not(:last-child):nth-child(even) {
                        background: rgba($orange-100, 0.5);
                    }
                }
            }
        }
    }

    &-type-2 {
        table-layout: fixed;

        thead {
            td,
            th {
                font-size: 0.75rem;
            }
        }

        tbody {
            tr {
                td {
                    font-size: 0.7500rem;
                    color: $black-200;
                    padding: 15px 13px !important;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    &:nth-child(even) {
                        background: $orange-100;
                    }

                    &:last-child {
                        // font-size: 1rem;
                        // color: $gray-900;
                    }
                    strong.relation {
                        color: $orange;
                    }
                }
            }
        }
    }
}
