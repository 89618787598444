

.expand-container {
  overflow: hidden;
}

.expand-content {
  margin-top: -100%;
  animation: collapse-kf 500ms forwards;
  max-height: 0;
}

.expand-content > * {
  display: none;
}
.expand-content.enabled > * {
  display: inherit;
}

@keyframes expand-kf {
  0%   {margin-top: -100%; max-height: 0}
  100% {margin-top: 0; max-height: unset}
}
@keyframes collapse-kf {
  0%   {margin-top: 0; max-height: unset}
  100% {margin-top: -100%; max-height: 0}
}

.expand-content.enabled.expanded {
  animation: expand-kf 500ms forwards;
  margin-top: 0;
  max-height: unset
}

.clickable {
  cursor: pointer;
}
