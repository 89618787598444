form {
    .select2-container--default {
        min-width: 100%;
    }
}
.select2-container--default {
    display: block;

    .select2-results__option {
        color: $black-200;
    }

    .select2-results__option--highlighted.select2-results__option--selectable {
        background: $gray-700;
        color: $black-200;
    }

    .select2-results__option--selected {
        background: $primary !important;
        color: $white !important;
    }

    .select2-selection--multiple {
        border: none !important;
        background: $gray-700;
        border-radius: $border-radius;
        min-height: $input-height;
        padding: 0;
        display: flex;

        .select2-selection__rendered {
            max-width: 100%;
            display: inline-block;
            vertical-align: middle;
            margin: 0;
            padding: 0 5px 5px 5px;

            &:not(:empty) {
                min-height: 80px;
            }
        }

        .select2-selection__choice {
            font-size: 1rem;
            color: $white;
            margin: 5px 5px 0 0;
            padding: 3px 36px 3px 8px;
            vertical-align: middle;
            border: none;
            background: $primary;
        }

        .select2-selection__choice__remove {
            font-size: 0;
            color: $white;
            text-align: center;
            padding: 0;
            border: none;
            border-radius: 100%;
            width: 18px;
            height: 18px;
            line-height: 18px;
            background: rgba($white, 0.2);
            left: auto;
            right: 9px;
            top: 6px;
            transition: $transition-base;
            background: rgba($white, 0.2) no-repeat center / 8px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8.614' height='8.64' viewBox='0 0 8.614 8.64'%3E%3Cg data-name='Group 1' transform='translate(-4.586 -4.586)'%3E%3Cpath d='M.321,5.517,5.785,0' transform='translate(6 6)' fill='none' stroke='%23fff' stroke-linecap='square' stroke-miterlimit='10' stroke-width='2'/%3E%3Cpath data-name='Line' d='M0,0,5.753,5.811' transform='translate(6 6)' fill='none' stroke='%23fff' stroke-linecap='square' stroke-miterlimit='10' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E");

            &:hover {
                background-color: rgba($white, 0.3);
                color: $white;
            }

            span {
                display: inline-block;
                margin: 0 0 0 -1px;
            }
        }
    }

    .select2-search--inline {
        display: inline-block;
        vertical-align: middle;

        .select2-search__field {
            min-height: $input-height;
            margin: 0;
            padding-top: 9px;
            padding-left: $input-padding-x;

            &::-webkit-input-placeholder { 
                opacity: 1;
                color: $black-200; 
            }
            &:-ms-input-placeholder { 
                opacity: 1;
                color: $black-200;  
            }
            &:-moz-placeholder { 
                opacity: 1;
                color: $black-200; 
            }
            &::-moz-placeholder { 
                opacity: 1;
                color: $black-200;
            }
        }
    }

}
.select2-dropdown {
    border: none;
    box-shadow: 0 2px 12px rgba(0,0,0,0.19);
}