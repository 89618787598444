// Color system
$white:    #fff !default;
$gray-100: #fafafc;
$gray-200: #e9ecef !default;
$gray-300: #cbcbcb;
$gray-400: #e3e3e6;
$gray-500: #d4d6d8;
$gray-600: #eaeced;
$gray-700: #f0f2f3;
$gray-800: #818283;
$gray-900: #707172;

$black:     #1a1a1e;
$black-100: #292526;
$black-200: #231f20;

$grays: (
"100": $gray-100,
"200": $gray-200,
"300": $gray-300,
"400": $gray-400,
"500": $gray-500,
"600": $gray-600,
"700": $gray-700,
"800": $gray-800,
"900": $gray-900
);

$blue:     #0272c1;
$blue-100: #42b1ff;
$blue-200: #deeffc;
$blue-300: #eff8fe;

$orange:  #f06c00 !default;
$orange-100: #ffe6d3 !default;

$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

$colors: (
"blue":       $blue,
"indigo":     $indigo,
"purple":     $purple,
"pink":       $pink,
"red":        $red,
"orange":     $orange,
"yellow":     $yellow,
"green":      $green,
"teal":       $teal,
"cyan":       $cyan,
"white":      $white,
"gray":       $gray-600,
"gray-dark":  $gray-800
);

$primary:       $orange   !default;
$secondary:     $gray-600 !default;
$success:       $green    !default;
$info:          $cyan     !default;
$info-light:    $blue-200;
$warning:       $yellow   !default;
$danger:        $red      !default;
$light:         $gray-100 !default;
$dark:          $gray-900;

$theme-colors: (
"primary":    $primary,
"secondary":  $secondary,
"success":    $success,
"info":       $info,
"info-light": $info-light,
"warning":    $warning,
"danger":     $danger,
"light":      $light,
"dark":       $dark,
"gray":       $gray-600,
"gray-light": $gray-700,
);

// Set the number of columns and specify the width of the gutters.
$grid-gutter-width: 20px;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1300px
);

$spacers: (
    0: 0,
    1: 5px,
    2: 10px,
    3: 15px,
    4: 20px,
    5: 25px,
    6: 30px,
    7: 35px,
    8: 40px,
    9: 45px,
    10: 50px
);

// Typography 
$font-family-base:                      "Helvetica Neue", Arial;
$font-family-title:                     "Helvetica Neue", Arial;
$font-size-sm:                          0.7500rem;
$font-weight-title:                     600;

// Define common padding and border radius sizes and more.
$border-radius:                         4px;
$border-color:                          $gray-400;

// Buttons 
$btn-padding-y:                         8px;
$btn-border-width:                      0;
$btn-disabled-opacity:                  0.2;

// Badges
$badge-font-size:                       0.75rem;
$badge-font-weight:                     400;
$badge-padding-y:                       7px;
$badge-padding-x:                       10px;
$badge-border-radius:                   100%;

// Sidebar
$sidebar-left-width:                    280px;
$sidebar-mobile-left-width:             70px;
$sidebar-right-width:                   400px;

// Navbar 
$navbar-height:                         90px;
$navbar-padding-y:                      24px;
$navbar-padding-x:                      20px;

// Forms
$input-height:                          40px;
$input-color:                           $black-200;
$input-bg:                              $gray-700;
$input-placeholder-color:               rgba($gray-900, 0.5);
$input-border-width:                    0;
$form-group-margin-bottom:              10px;
$form-grid-gutter-width:                30px;

$custom-select-indicator:               url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.414' height='10.121' viewBox='0 0 17.414 10.121'%3E%3Cpath id='Stroke_1' data-name='Stroke 1' d='M16,0,8,8,0,0' transform='translate(0.707 0.707)' fill='none' stroke='%239d9ea0' stroke-miterlimit='10' stroke-width='2'/%3E%3C/svg%3E");
$custom-select-bg-size:                 17px 10px;

$input-group-addon-bg:                  $gray-700;

// Nav tabs
$nav-tabs-link-active-color:            $orange;
$nav-tabs-border-radius:                0;
$nav-tabs-link-active-border-color:     transparent transparent $orange;
$nav-tabs-link-hover-border-color:      transparent transparent $gray-700;

$nav-pills-link-active-color:           $primary;
$nav-pills-link-active-bg:              $orange-100;

// Tables
$table-color:                           $black-200;
$table-head-color:                      $gray-900;
$table-head-bg:                         $white;
$table-th-font-weight:                  400;
$table-hover-bg:                        $orange-100;

// Dropdowns
$dropdown-min-width:                    265px;
$dropdown-padding-x:                    0;
$dropdown-padding-y:                    16px;
$dropdown-border-radius:                0;
$dropdown-border-width:                 0;

$dropdown-link-hover-color:             $orange-100;
$dropdown-link-hover-bg:                transparent;

$dropdown-link-active-color:            $orange-100;
$dropdown-link-active-bg:               transparent;

$dropdown-item-padding-x:               20px;
$dropdown-item-padding-y:               5px;

// Modals 
$modal-content-border-width:            0;
$modal-content-border-radius:           0;
$modal-md:                              690px;
$modal-inner-padding:                   40px 50px;
$modal-content-color:                   $black-200;

$modal-backdrop-bg:                     rgba($black, 0.54);
$modal-backdrop-opacity:                1;

// Images 
$thumbnail-padding:                     10px;

:export {
    primary: $primary;
}
