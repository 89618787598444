html {
    font-size: 16px;
}

.main {
    padding-top: $navbar-height;

    &-with-right-content-sidebar {
        @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
            .content-left {
                min-height: calc(100vh - 90px);
                padding-right: $grid-gutter-width / 2;
            }

            .filters-header-wrap .filters-header--fixed {
                right: $sidebar-right-width;
            }

            padding-right: $sidebar-right-width;
        }

        @media screen and (min-width: map-get($grid-breakpoints, 'md')) and (max-width: map-get($grid-breakpoints, 'xl') - 1) {
            padding-right: 350px;
        }
    }

    &-with-left-sidebar {
        padding-left: 0;


        @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
            padding-left: $sidebar-mobile-left-width;

            .filters-header-wrap .filters-header--fixed,
            .navbar {
                left: $sidebar-mobile-left-width + $grid-gutter-width / 2;
            }
        }

        @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
            padding-left: $sidebar-left-width;

            .filters-header-wrap .filters-header--fixed,
            .navbar {
                left: $sidebar-left-width + $grid-gutter-width / 2;
            }
        }

    }

    &-with-right-sidebar {
        @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
            padding-right: $sidebar-right-width;

            .filters-header-wrap .filters-header--fixed,
            .navbar {
                right: $sidebar-right-width + $grid-gutter-width / 2;
            }
        }

        @media screen and (min-width: map-get($grid-breakpoints, 'md')) and (max-width: map-get($grid-breakpoints, 'xl') - 1) {
            padding-right: 350px;

            .filters-header-wrap .filters-header--fixed,
            .navbar {
                right: 350px + $grid-gutter-width / 2;
            }
        }
    }
}

.content {
    min-height: calc(100vh - 90px);
    @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
        max-height: calc(100vh - 90px);
        min-height: calc(100vh - 90px);
        // overflow: auto;
    }

    &-fullscreen {
        max-height: 100vh;
        min-height: 100vh;
        // overflow: auto;
    }

    &-with-pager {
        max-height: calc(100vh - 90px - 44px);
        min-height: calc(100vh - 90px - 44px);
        overflow: auto;
    }

    &-btn-back {
        display: inline-block;

        &:hover {
            text-decoration: none;
            color: $black-200;
        }

        &-icon {
            display: inline-block;
            vertical-align: top;
            margin: 3px 5px 0 0;
        }
    }

    &-header {
        padding: 20px 20px 20px;
        position: relative;
        border-bottom: 1px solid $gray-400;

        .icon {
            margin-right: 20px;

            &-mark {
                margin-top: 4px;
            }

            &-alarm {
                margin-top: 3px;
            }
        }

        @media screen and (max-width: map-get($grid-breakpoints, 'md') - 1) {
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }

    &-title {
        font-family: $font-family-title;
        font-weight: $font-weight-title;
        font-size: 2.4rem;
        line-height: 1.1;
        color: $black;
        position: relative;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding: 0 0 5px;

        & > div:first-child {
            -ms-flex: 0 1 auto
        }

        .dropdown-menu {
            top: 3px;
        }

        &.large {
            font-size: 7.5rem;
        }

        &.small {
            font-size: 2.5rem;
        }

        @media screen and (max-width: map-get($grid-breakpoints, 'md') - 1) {
            font-size: 2rem;
        }

        @media screen and (max-width: map-get($grid-breakpoints, 'xl') - 1) {
            flex-wrap: wrap;
        }

        &-icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0; 

            &-file {
                top: 3px;
                transform: none;
            }
        }
    }

    &-subtitle {
        font-size: 0.8500rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .dropdown-menu {
            top: -10px;
        }
    }

    &-section {
        padding: 40px 20px;

        &:not(:last-child) {
            border-bottom: 1px solid $gray-400;
        }

        &-title {
            font-size: 1.3750rem;
            color: $black-200;
            padding: 0 0 18px;
        }

        &-subtitle {
            font-size: 0.7500rem;
            color: $black-200;
            text-transform: uppercase;
            padding: 0 0 26px;
        }

        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &-left {
        @media screen and (max-width: map-get($grid-breakpoints, 'md') - 1) {
            border-bottom: 1px solid $gray-400;
        }
    }

    &-sidebar {
        padding: 0 0 30px;

        @media screen and (max-width: map-get($grid-breakpoints, 'md') - 1) {
            padding-top: 35px;
        }

        @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
            position: fixed;
            top: $navbar-height + 5px;
            right: $grid-gutter-width / 2;
            width: $sidebar-right-width;
            max-height: calc(100vh - #{$navbar-height + 5px});
            overflow: auto;
            padding-left: $grid-gutter-width / 2;
        }

        @media screen and (min-width: map-get($grid-breakpoints, 'md')) and (max-width: map-get($grid-breakpoints, 'xl') - 1) {
            width: 350px; 
        }

        &-title {
            font-size: 1.3750rem;
            color: $black-100;
        }

        &-subtitle {
            font-size: 0.6875rem;
            color: $black-100;
            text-transform: uppercase;
        }

        &-image {
            padding: 10px;
        }
    }
}
