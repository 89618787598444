.narrow-years-range-year {
    display: none;
    font-family: $font-family-title;
    font-weight: $font-weight-title;
    font-size: 3rem;
    line-height: 1;
    color: $black-200;
    padding: 4px 42px 0 17px;


    @media screen and (min-width: map-get($grid-breakpoints, 'sm')) {
        display: block;
    }

    @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
        display: none;
    }
}

.main-with-right-sidebar {
    .narrow-years-range-year {
        display: none;

        @media screen and (min-width: map-get($grid-breakpoints, 'sm')) {
            display: block;
        }

        @media screen and (min-width: 1368px) {
            display: none;
        }
    }

    .years-range {
        width: calc(100vw - 112px - 0px);

        @media screen and (min-width:  map-get($grid-breakpoints, 'md')) {
            width: calc(100vw - 320px - 140px);
        }
        @media screen and (min-width:  map-get($grid-breakpoints, 'lg')) {
            width: calc(100vw - 320px - 350px);
        }
        @media screen and (min-width:  map-get($grid-breakpoints, 'xl')) {
            width: calc(100vw - 320px - 400px);
        }


        &-year {
            display: none;

            @media screen and (min-width: 1368px) {
                display: block;
            }
        }
    }
}


.years-range.filters {
    width: calc(100vw - 128px);

    @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
        width: calc(100vw - 338px);
    }

}

.years-range {
    display: none;
    flex-wrap: wrap;
    padding: 3px 0 5px 17px;
    border-bottom: 1px solid $gray-400;
    overflow-x: auto;
    overflow-y: hidden;
    min-height: 76px;
    width: calc(100vw - 37px);

    @media screen and (min-width: map-get($grid-breakpoints, 'sm')) {
        display: flex;
    }

    @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
        width: calc(100vw - 107px);
    }


    @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
        width: calc(100vw - 320px);
    }



    &-year {
        font-family: $font-family-title;
        font-weight: $font-weight-title;
        font-size: 3rem;
        line-height: 1;
        color: $black-200;
        padding: 4px 42px 0 0;
        display: none;

        @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
            display: block;
        }
    }

    &-list {
        display: flex;
        flex-grow: 1;
        position: relative;
        padding: 36px 30px 10px;
        
        &::before {
            content: "";
            position: absolute;
            top: 30px;
            left: 0;
            right: 0;
            height: 2px;
            background: $black-200;
        }
        
        &-inner {
            display: flex;
            overflow-x: auto;
            flex: 0 0 100%;
        }
    }

    &-item {
        display: block;
        font-size: 0.7500rem;
        color: $gray-900;
        padding: 0 10px;
        position: relative;
        flex-grow: 1;
        -ms-flex-positive: 1;
        text-align: center;

        &::before {
            content: "";
            position: absolute;
            top: -26px;
            left: 0;
            right: 0;
            height: 4px;
            background: $primary;
            max-width: 75px;
            margin: 0 auto;
            opacity: 0;
        }

        &.active,
        &:hover {
            color: $primary;
            text-decoration: none;
        }

        &.active {
            &::before {
                opacity: 1;
            }
        }
    }

    &-prev,
    &-next {
        position: absolute;
        top: 30px;
        width: 30px;
        height: 31px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            path {
                stroke: $primary;
            }
        }
    }

    &-prev {
        left: 0;
    }

    &-next {
        right: 0;
    }
}

