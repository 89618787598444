@import "styles/base/variables";

.risk-events {
  margin-top: 0.5em;
  .risk-designation {
    padding: 1rem 1em 0.5em;
    .risk-score { float: right !important; font-size: 2rem; line-height: 2rem;}
    .risk-tag { color: $primary; font-size: 1.2rem;}
    .risk-category { font-weight: bold; }
    .risk-summary { padding: 0.7em 0 0.5em; }
    .risk-event-examples-header { text-align: right; color: $primary }
    .risk-examples {
      .risk-example {
        font-size: 0.9rem; margin: 0 0 1.5rem 1.5rem;
        .risk-example-location, .risk-example-separator, .risk-example-date { display: inline-block; font-weight: bold; font-size: 1.12rem }
        .risk-example-separator { padding: 0 0.5rem; }
        .risk-example-summary { padding: 0.5rem 0; }
        .risk-example-citation {
          font-size: 0.8rem;
          //a { color: $gray-900 !important; }
          font-style: italic;
        }
      }
    }
  }
  .related-risk-designation {
    padding: 1rem 1em 0.5em;
    .related-risk-header {
      padding-bottom: 5px;
      line-height: 1;
      .related-risk-company { font-size: 1.2rem; display: inline-block; }
      .related-risk-relation { float: right; color: $primary; padding: 0.5rem; }
    }
    .related-risk-event, .related-risk-separator, .related-risk-date, .related-risk-location { display: inline-block; font-size: 0.75rem; }
    .related-risk-separator { padding: 0 0.5rem; }
    .related-risk-summary { padding: 0.5rem 0; }
  }
}
