@media screen {

  .print-only {
    display: none;
  }
  .print-page-header {
    display: none;
  }
  .print-page-footer {
    display: none;
  }

  .print-margins {
    padding: 0;
  }
}

@media print {

  .main {
    padding-top: 0 !important;
    height: 100%;
    font-size: 80%;
  }

  .sidebar-left {
    display: none !important;
  }

  .pager-control {
    display: none !important;
  }

  .no-print {
    display: none !important;
  }

  .print-page-header {
    margin-left: 30px;
    img {
      max-width: 100px !important;
    }
  }

  .print-page-footer {
    margin-left: 30px;
    font-family: Roboto, "Helvetica Neue", Arial;
  }

  .content-with-pager {
    max-height: unset;
    min-height: unset;
    overflow: unset;
  }


  .document {
    h1 {
      page-break-inside: avoid;
    }
    h1::after {
      content: "";
      display: block;
      height: 100px;
      margin-bottom: -100px;
    }
  }

  .print-on-same-page {
    page-break-inside: avoid;
  }

  .border-right {
    border-right: unset !important;
  }
  .content-header {
    border-bottom: unset !important;
  }
  .content-section {
    padding-top: 5px !important;
  }

  body {
    min-width: unset !important;
  }
}


@page {
  size: letter portrait;
  margin: 18mm;
}