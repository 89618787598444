// Variables
@import "styles/base/variables";

.EntityTable {
  colgroup {
    @media screen and (min-width: map-get($grid-breakpoints, 'xl')) {
      col.name {
        // min-width: 300px;
      }
      col.risk {
        width: 80px;
      }
      col.risk-score {
        width: 65px;
      }
    }

    @media screen and (max-width: map-get($grid-breakpoints, 'xl') - 1) {
      col.name {
        // min-width: 300px;
      }
      col.risk {
        width: 50px;
      }
      col.risk-score {
        width: 65px;
      }
    }
  }
  colgroup.compact {
    col.name {
      // min-width: 300px;
    }
    col.risk {
      width: 50px;
    }
    col.risk-score {
      width: 65px;
    }
  }
}