.dropdown {
    font-family: "Roboto";
    text-transform: none;
    margin-left: auto;

    &-menu {
        padding-top: 0;
        top: 0;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.19);
    }

    &-header {
        text-align: right; 
        padding-right: 0;

        .btn {
            line-height: 0.5;
        }
    }

    &-item {
        &.active {
            background: $primary;
            color: $white;
            
            .icon-fill {
                fill: $white;
            }
            
            .icon-stroke {
                stroke: $white;
            }
        }
        
        &-icon {
            display: inline-flex;
            vertical-align: middle;
            justify-content: center;
            align-items: center;
            width: 35px;
            height: 25px;
            margin: -3px 0 0 0;
        }
    }
}


.d-flex > .dropdown {
    margin-left: unset;
}


.dropdown-item:hover, .dropdown-item:focus {
    color: #16181b !important;
    text-decoration: none;
    background-color: #e9ecef;
}
