.login {
  .title {
    margin-top: 2rem;
    margin-bottom: 3rem;
    img {
      display: block;
      margin: auto;
      max-width: 80%;
    }
  }
}