.nav-tabs {
    .nav-link {
        color: $gray-900;
        padding-top: 18px;
        padding-bottom: 14px;
        border: none;
        border-bottom: 4px solid transparent;

        svg {
            margin: -3px 8px 0 0;
        }

        &.active {
            svg {
                margin: -3px 8px 0 0;

                .icon-fill {
                    fill: $nav-tabs-link-active-color;
                }

                .icon-stroke {
                    stroke: $nav-tabs-link-active-color;
                }
            }
        }
    }
}

.nav-pills {
    .nav-item {
        padding: 10px;
    }

    .nav-link {
        font-size: 0.8125rem;
        color: $gray-900;
        background: $gray-700;
        transition: $transition-base;
        white-space: nowrap;

        &:not(.active):hover {
            background: darken($gray-700, 5%);
        }
    }
}

// Use z-index for tab visibility for content that must render underneath
// (like the mapbox map)
.nav-tab-content {
    position: relative;
    .tab-pane {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
        z-index: 1;
        width: 100% !important;
        height: 100% !important;
    }
    .active-tab {
        z-index: 100;
    }
}
