.dot {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0.45em;
        width: 9px;
        height: 9px;
        border-radius: 100%;
        background: $gray-700;
    }

    &-v-center {
        &::before {
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &-left {
        padding-left: 14px;

        &::before {
            left: 0;
        }
    }

    &.active {
        &::before {
            background-color: $primary;
        }
    }
}