.filters {
    &-header {
        padding: 5px;
        border-bottom: 1px solid $gray-400;

        @media screen and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
            padding-left: 0;
            padding-right: 0;
        }

        &-wrap {
            position: relative;
            z-index: 1;

            .filters-header {
                position: absolute;
                top: 0;
                left: 0;
                right: $grid-gutter-width / 2;

                &--fixed {
                    position: fixed;
                    top: 90px;
                    z-index: 1001;
                    background: $white;
                }
            }
        }


        .btn {
            padding-top: 11px;
            padding-bottom: 11px;
            min-width: 100%;

            &.active {
                background: $orange-100 !important;
                color: $primary !important;
            }

            .badge-light {
                background: rgba($white, 0.19);
            }

            svg {
                vertical-align: middle;
                margin: -2px 0 0 5px;
            }
        }

        .btn-filters {
            min-width: 100px;
        }

        .btn-reset {
            min-width: 90px;
        }

        .custom-select {
            @media screen and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
                font-size: 12px;
            }
        }

        &-item {
            padding: 5px;

            @media screen and (max-width: map-get($grid-breakpoints, 'md') - 1) {
                flex-grow: 1;

                .btn {
                    width: 100%;
                }
            }
        }
    }

    &-form {
        padding: 30px 20px;

        @media screen and (max-width: map-get($grid-breakpoints, 'md') - 1) {
            padding-left: 0;
            padding-right: 0;
        }

        &-results {
            font-size: 0.8750rem;
        }

        .form-control {
            min-width: 220px;
        }

        .accordion {

            border-radius: 0;
            border: none;
            border-left: none;
            border-right: none;
            border-bottom: $card-border-width solid $card-border-color;
            &:first-of-type {
                border-top: $card-border-width solid $card-border-color;
            }


            .card {
                overflow: visible;
                border: none;


                &-header {
                    color: $orange;
                    background: none;
                    padding: 10px 10px 8px 50px;
                    position: relative;
                    border-bottom: none;

                    @media screen and (max-width: map-get($grid-breakpoints, 'md') - 1) {
                        padding-left: 40px;
                    }

                    &.active {
                        &::before {
                            background: $orange;
                        }
                    }

                    &::before {
                        content: '';
                        width: 12px;
                        height: 12px;
                        border-radius: 100%;
                        background: $gray-500;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 20px;
                        margin: 1px 0 0;

                        @media screen and (max-width: map-get($grid-breakpoints, 'md') - 1) {
                            left: 10px;
                        }

                        @media screen and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
                            top: 23px;
                            transform: none;
                        }
                    }

                    .btn {
                        color: $black-200;
                        padding-left: 0;
                        padding-right: 0;
                    }
                }

                &-body {
                    padding: 10px 10px 24px 50px;

                    @media screen and (max-width: map-get($grid-breakpoints, 'md') - 1) {
                        padding-left: 40px;



                    }
                }
            }
        }

        &-footer {
            padding: 30px 0 0;

            .form-group {
                max-width: 370px;

                @media screen and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
                    max-width: 100%;
                }
            }

            &-text {
                font-size: 0.8750rem;
                color: $black-200;
                position: relative;
                padding-left: 28px;
            }

            &-icon {
                position: absolute;
                top: 0;
                left: 0;
            }

            &-btns {
                font-size: 0;

                .btn {
                    margin-right: 10px;
                    margin-bottom: 10px;
                    min-width: 180px;

                    @media screen and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
                        width: 100%;
                    }
                }
            }
        }
    }
}
