/*------------------------------------------------------*/
/*--- Font Family :: [BebasNeuePro] :: Regular ---*/
/*------------------------------------------------------*/
@font-face {
    font-family: 'BebasNeuePro';
    src:url('../fonts/bebas-neue-pro.woff2') format('woff2'),
        url('../fonts/bebas-neue-pro.woff') format('woff');
    font-weight: normal !important;
    font-style: normal !important;
}
/*------------------------------------------------------*/
/*--- Font Family :: [Roboto] :: Regular ---*/
/*------------------------------------------------------*/
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto-regular.eot');
    src: url('../fonts/roboto-regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/roboto-regular.woff') format('woff'),
        url('../fonts/roboto-regular.ttf') format('truetype'),
        url('../fonts/roboto-regular.svg#robotoregular') format('svg');
    font-weight: normal !important;
    font-style: normal !important;
}