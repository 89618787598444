.navbar {
    height: $navbar-height;
    border-bottom: 1px solid $gray-400;
    //padding-left: 10px;
    padding-right: 20px;
    position: fixed;
    top: 0;
    left: 0;
    right: $grid-gutter-width;
    background: $white;
    z-index: 1001;
    
    @media screen and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
        padding-left: 10px;
        padding-right: 0;
    }


    @media screen and (min-width: map-get($grid-breakpoints, 'sm')) {
        right: 0;
    }

    .custom-select-wrap {
        flex-grow: 1;
    }

    .input-group-search {
        .dropdown-menu {
            position: absolute;
            top: 40px;
            display: none;
            background-color: $white;
            min-width: 200px;
            border: 1px solid $gray-200;
            padding: 0;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.19);
            z-index: 100;
            &.show {
                display: block;
            }
            button, a {
                width: 100%;
                padding: 10px 20px;
                text-align: left;
                background: none;
                color: inherit;
                border: none;
                font: inherit;
                cursor: pointer;
                outline: inherit;
                &:hover {
                    background-color: $gray-100;
                }
            }
        }
    }

    @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
        .hamburger-menu {
            display: none;
        }
    }
}
