@import "../../../styles/base/variables";

div.risk-box {
  border: 1px solid $gray-600;
  padding: 10px;
}

div.sub-title {
  font-size: x-small;
}

div.risk-name {
  overflow-x: hidden;
  white-space: nowrap;
  margin-right: 5px;
}

div.entity-title {
  flex-shrink: 0 !important;
  white-space: nowrap;
  color: $orange;
}

div.caret {
  flex-shrink: 0 !important;
  width: 28px;
  display: inline-block;
  margin-left: 8px
}

div.entity-name {
  color: $orange;
  margin-left: 30px;
  margin-top: 8px;
  margin-right: 5px;
  white-space: nowrap;
  overflow-x: hidden;
}

div.entity-relationship {
  flex-shrink: 0 !important;
  white-space: nowrap;
  margin-top: 8px;
}
