.pager-control {
  font-size: $font-size-sm;
  color: $gray-800;
  background-color: $white;
  border-top: 1px solid $gray-400;
  padding: 8px 10px;
  margin: 0 -10px;
  .pager-left {
    min-width: 210px;
  }
  .pager-right {
    white-space: nowrap;
    min-width: 210px;
  }
  .control {
    display: inline-block;
    padding: 3px 11px;
    &.editable {
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  .pills {
    margin-right: 5px;
    .pill {
      border-style: solid;
      border-width: 1px 0 1px 1px;
      border-color: $gray-400;
      &:hover {
        cursor: pointer;
        background-color: $gray-100;
      }
      &:first-child {
        padding-left: 14px;
        border-radius: 18px 0 0 18px;
      }
      &:last-child {
        padding-right: 14px;
        border-width: 1px;
        border-radius: 0 18px 18px 0;
      }
      &.selected {
        background-color: $gray-200;
      }
    }
  }
  .step {
    display: inline-block;
    border-radius: 12px;
    height: 24px;
    width: 24px;
    background-color: $gray-200;
    margin-right: 5px;
    &.left {
      padding: 2px 0 4px 6px;
    }
    &.right {
      padding: 2px 0 4px 9px;
    }
    &:hover {
      cursor: pointer;
      background-color: $gray-300;
    }
  }
  input {
    display: inline-block;
    margin-right: 4px;
    height: 24px;
    font-size: 12px;
    width: 50px;
  }
}
.pager-control.non-preview {
  padding: 8px 0;
  margin: 0 0;
}
