.modal {

    &-body {
        box-shadow: 0 2px 12px 0 rgba($black, 0.19);
        
        @media screen and (max-width: map-get($grid-breakpoints, 'md') - 1) {
            padding: 25px 30px;
        }
    }

    &-title {
        font-family: $font-family-title;
        font-size: 3rem;
        line-height: 1.1;
        color: $primary;

        @media screen and (max-width: map-get($grid-breakpoints, 'md') - 1) {
            font-size: 2rem;
        }
    }

    &-btns {
        font-size: 0;

        .btn {
            //margin-right: 10px;
            margin-bottom: 10px;
            min-width: 180px;

            @media screen and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
                width: 100%;
            }
        }
    }

    &-form {
        &-text {
            font-size: 0.8750rem;
            color: $black-200;
        }
    }
}
